@import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-dosis;
  }
}

.homePageDirButton {
  @apply bg-zinc-100 text-xl text-zinc-900 font-bold tracking-wider ease-in-out
  duration-200 px-3 py-2 rounded-3xl hover:scale-110 hover:bg-zinc-900 hover:text-white;
}
.header {
  @apply text-5xl tracking-wider font-bold;
}
